/**
 *   Unslider by @idiot and @damirfoy
 *   Contributors:
 *   - @ShamoX
 *
 */

(function($, f) {
    var Unslider = function() {
        //  Object clone
        var _ = this;

        //  Set some options
        _.o = {
            speed: 500,     // animation speed, false for no transition (integer or boolean)
            delay: 3000,    // delay between slides, false for no autoplay (integer or boolean)
            init: 0,        // init delay, false for no delay (integer or boolean)
            pause: !f,      // pause on hover (boolean)
            loop: !f,       // infinitely looping (boolean)
            keys: f,        // keyboard shortcuts (boolean)
            dots: f,        // display ••••o• pagination (boolean)
            arrows: f,      // display prev/next arrows (boolean)
            prev: '←',      // text or html inside prev button (string)
            next: '→',      // same as for prev option
            fluid: f,       // is it a percentage width? (boolean)
            starting: f,    // invoke before animation (function with argument)
            complete: f,    // invoke after animation (function with argument)
            items: '>ul',   // slides container selector
            item: '>li',    // slidable items selector
            easing: 'swing',// easing function to use for animation
            autoplay: true  // enable autoplay on initialisation
        };

        _.init = function(el, o) {
            //  Check whether we're passing any options in to Unslider
            _.o = $.extend(_.o, o);

            _.el = el;
            _.ul = el.find(_.o.items);
            _.max = [el.outerWidth() | 0, el.outerHeight() | 0, el.css('max-width')];
            _.li = _.ul.find(_.o.item).each(function(index) {
                var me = $(this),
                    width = me.outerWidth(),
                    height = me.outerHeight();

                //  Set the max values
                if (width > _.max[0]) _.max[0] = width;
                if (height > _.max[1]) _.max[1] = height;
            });


            //  Cached vars
            var o = _.o,
                ul = _.ul,
                li = _.li,
                len = li.length;

            //  Current indeed
            _.i = 0;

            //  Set the main element
            if (o.fluid && _.max[2] === 'none') el.css({'max-width': '100%'});
            el.css({width: _.max[0], height: li.first().outerHeight(), overflow: 'hidden'});

            //  Set the relative widths
            ul.css({position: 'relative', left: 0, width: (len * 100) + '%'});
            li.css({'float': 'left', width: (_.max[0]) + 'px'});

            //  Autoslide
            o.autoplay && setTimeout(function() {
                if (o.delay | 0) {
                    _.play();

                    if (o.pause) {
                        el.on('mouseover mouseout', function(e) {
                            _.stop();
                            e.type == 'mouseout' && _.play();
                        });
                    };
                };
            }, o.init | 0);

            //  Keypresses
            if (o.keys) {
                $(document).keydown(function(e) {
                    var key = e.which;

                    if (key == 37)
                        _.prev(); // Left
                    else if (key == 39)
                        _.next(); // Right
                    else if (key == 27)
                        _.stop(); // Esc
                });
            };

            //  Dot pagination
            o.dots && nav('dot');

            //  Arrows support
            o.arrows && nav('arrow');

            //  Patch for fluid-width sliders. Screw those guys.
            if (o.fluid) {
                $(window).resize(function() {
                    _.r && clearTimeout(_.r);

                    _.r = setTimeout(function() {
                        var styl = {height: li.eq(_.i).outerHeight()},
                            width = el.outerWidth();

                        ul.css(styl);
                        li.css({width: width});
                        styl['width'] = 'auto';
                        el.css(styl);
                    }, 50);
                }).resize();
            };

            //  Swipe support
            if ($.event.special['swipe'] || $.Event('swipe')) {
                el.on('swipeleft swiperight swipeLeft swipeRight', function(e) {
                    e.type.toLowerCase() == 'swipeleft' ? _.next() : _.prev();
                });
            };

            return _;
        };

        //  Move Unslider to a slide index
        _.to = function(index, callback) {
            if (_.t) {
                _.stop();
                _.play();
            }
            var o = _.o,
                el = _.el,
                ul = _.ul,
                li = _.li,
                current = _.i,
                target = li.eq(index);

            $.isFunction(o.starting) && !callback && o.starting(el, li.eq(current));

            //  To slide or not to slide
            if ((!target.length || index < 0) && o.loop == f) return;

            //  Check if it's out of bounds
            if (!target.length) index = 0;
            if (index < 0) index = li.length - 1;
            target = li.eq(index);

            var speed = callback ? 5 : o.speed | 0,
                easing = o.easing,
                obj = {height: target.outerHeight()};

            if (!ul.queue('fx').length) {
                //  Handle those pesky dots
                el.find('.dot').eq(index).addClass('active').siblings().removeClass('active');

                el.animate(obj, speed, easing) && ul.animate($.extend({left: '-' + index + '00%'}, obj), speed, easing, function(data) {
                    _.i = index;

                    $.isFunction(o.complete) && !callback && o.complete(el, target);
                });
            };
        };

        //  Autoplay functionality
        _.play = function() {
            _.t = setInterval(function() {
                _.to(_.i + 1);
            }, _.o.delay | 0);
        };

        //  Stop autoplay
        _.stop = function() {
            _.t = clearInterval(_.t);
            return _;
        };

        //  Move to previous/next slide
        _.next = function() {
            return _.stop().to(_.i + 1);
        };

        _.prev = function() {
            return _.stop().to(_.i - 1);
        };

        //  Create dots and arrows
        function nav(name, html) {
            if (name == 'dot') {
                html = '<ol class="dots">';
                $.each(_.li, function(index) {
                    html += '<li class="' + (index == _.i ? name + ' active' : name) + '">' + ++index + '</li>';
                });
                html += '</ol>';
            } else {
                html = '<div class="';
                html = html + name + 's">' + html + name + ' prev">' + _.o.prev + '</div>' + html + name + ' next">' + _.o.next + '</div></div>';
            };

            _.el.addClass('has-' + name + 's').append(html).find('.' + name).click(function() {
                var me = $(this);
                me.hasClass('dot') ? _.stop().to(me.index()) : me.hasClass('prev') ? _.prev() : _.next();
            });
        };
    };

    //  Create a jQuery plugin
    $.fn.unslider = function(o) {
        var len = this.length;

        //  Enable multiple-slider support
        return this.each(function(index) {
            //  Cache a copy of $(this), so it
            var me = $(this),
                key = 'unslider' + (len > 1 ? '-' + ++index : ''),
                instance = (new Unslider).init(me, o);

            //  Invoke an Unslider instance
            me.data(key, instance).data('key', key);
        });
    };

    Unslider.version = "1.0.0";
})(jQuery, false);